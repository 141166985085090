<!-- <template>
  <div>
    <navbar></navbar>
    <div class="flex flex-col justify-center w-full">
      <h1>Register</h1>
      <form @submit.prevent="register">
        <div>
          <input type="text" v-model="username" placeholder="Username" required>
        </div>
        <div>
          <input type="password" v-model="password" placeholder="Password" required>
        </div>
        <div>
          <input type="email" v-model="email" placeholder="Email" required>
        </div>
        <div>
          <select v-model="selectedRole" class="w-full" id="role" required>
            <option disabled value="">Select a role</option>
            <option value="student">Student</option>
            <option value="coach">Coach</option>
          </select>
        </div>
        <button type="submit">Register</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Navbar from './Navbar.vue';

export default {
  components: { Navbar },
  data() {
    return {
      username: '',
      password: '',
      email: '',
      selectedRole: '',
    };
  },
  methods: {
    register() {
      axios.post('/api/auth/register/', {
        username: this.username,
        email: this.email,
        password: this.password,
        role: this.role
      })
        .then(response => {
          // Optionally log in the user after registration
          this.loginAfterRegister();
        })
        .catch(error => {
          console.error('Error registering:', error);
        });
    },
    loginAfterRegister() {
      axios.post('/api/auth/login/', {
        username: this.username,
        password: this.password
      })
        .then(response => {
          localStorage.setItem('access_token', response.data.access);
          localStorage.setItem('refresh_token', response.data.refresh);
          // Redirect to the dashboard or another page
          this.$router.push('/dashboard');
        })
        .catch(error => {
          console.error('Error logging in:', error);
        });
    }
  }
};
</script>

<style scoped>
.register {
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
select {
  padding: 8px;
  margin-top: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  display: inline-block;
  padding: 10px 15px;
  background: #009900;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background: #009900;
}
</style> -->

<template>
  <div>
    <navbar></navbar>
    <div class="">
      <div>
        <form @submit.prevent="register"
          class="register-form w-full max-w-xl bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h1 class="text-3xl text-center font-bold mb-6">Register</h1>
          <div class="grid grid-rows-3 grid-cols-2 grid-flow-col gap-4">
            <div class="form-group mb-4">
              <label for="firstname" class="block text-gray-700 text-sm font-bold mb-2">First Name</label>
              <input type="text" v-model="first_name" id="firstname" placeholder="First Name" required
                class="input-field w-full px-3 py-2 border rounded" />
            </div>
            <div class="form-group mb-4">
              <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email</label>
              <input type="email" v-model="email" id="email" placeholder="Email" required
                class="input-field w-full px-3 py-2 border rounded" />
            </div>
            <div class="form-group mb-4">
              <label for="password" class="block text-gray-700 text-sm font-bold mb-2">Password</label>
              <input type="password" v-model="password" id="password" placeholder="Password" required
                class="input-field w-full px-3 py-2 border rounded" />
            </div>
            <div class="form-group mb-4">
              <label for="lastname" class="block text-gray-700 text-sm font-bold mb-2">Last Name</label>
              <input type="text" v-model="last_name" id="lastname" placeholder="Last Name" required
                class="input-field w-full px-3 py-2 border rounded" />
            </div>
            <div class="form-group mb-4">
              <label for="phone_number" class="block text-gray-700 text-sm font-bold mb-2">Phone No.</label>
              <input type="text" v-model="phone_number" id="phone" placeholder="Phone No." required
                class="input-field w-full px-3 py-2 border rounded" />
            </div>
            <div class="form-group mb-4">
              <label for="password_confirmation" class="block text-gray-700 text-sm font-bold mb-2">Confirm
                Password</label>
              <input type="password" v-model="password_confirmation" id="password_confirmation"
                placeholder="Confirm Password" required class="input-field w-full px-3 py-2 border rounded" />
            </div>
          </div>
          <!-- <div class="form-group mb-4">
            <label for="role" class="block text-gray-700 text-sm font-bold mb-2">Role</label>
            <select v-model="selectedRole" id="role" required class="input-field w-full px-3 py-2 border rounded">
              <option disabled value="">Select a role</option>
              <option value="student">Student</option>
              <option value="coach">Coach</option>
            </select>
          </div> -->
          <div class="form-group flex items-center justify-between">
            <button type="submit"
              class="submit-button w-full bg-dark-green-theme hover:bg-green-700 text-white font-bold py-2 px-4 rounded my-4">Register</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Navbar from './Navbar.vue';
import { base_url } from '../../../config/config';

export default {
  components: { Navbar },
  data() {
    return {
      first_name: '',
      last_name: '',
      role: 'user',
      password: '',
      email: '',
      phone_number: '',
      password_confirmation: ''
    };
  },
  methods: {
    register() {

      if (this.password != this.password_confirmation) {
        this.$toast.error("Password should match confirm password", {
          position: 'top',
        })
      }

      let formData = new FormData();
      formData.append('first_name', this.first_name);
      formData.append('last_name', this.last_name);
      formData.append('email', this.email);
      formData.append('password', this.password);
      formData.append('phone_number', this.phone_number);
      formData.append('role', this.role);
      formData.append('password_confirmation', this.password_confirmation);

      axios.post(`${base_url}/api/user/create`, formData).then(response => {
        this.$toast.success(response.data.message, {
          position: 'top',
        });
        this.$router.push("/login")
      }).catch(error => {
        console.error('Error registering:', error);
      });
    },
    // loginAfterRegister() {
    //   axios.post('/api/login/', {
    //     email: this.email,
    //     password: this.password,
    //   })
    //     .then(response => {
    //       localStorage.setItem('access_token', response.data.access_token);
    //       localStorage.setItem("user", JSON.stringify(response.data.data.user));
    //       this.$router.push('/coaches');
    //     })
    //     .catch(error => {
    //       console.error('Error logging in:', error);
    //     });
    // },
  },
};
</script>

<style scoped>
.register-form {
  background: #ffffff;
  padding: 2% 4%;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 40%;
  margin: 20px auto;
}

.input-field {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group {
  margin-bottom: 16px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

button[disabled],
input[disabled] {
  cursor: not-allowed;
  background: #cccccc;
}
</style>
