<template>
    <div>
        <div class="pb-20">
            <Navbar />
        </div>
        <Sidebar />
        <main class="ml-64">
            <slot></slot>
        </main>
    </div>
</template>

<script>
import Navbar from '@/components/Admin/Navbar.vue';
import Sidebar from '@/components/Admin/Sidebar.vue';

export default {
    components: { Navbar, Sidebar },
};
</script>

<style scoped>
main {
    padding: 20px;
}
</style>