<template>
    <Navbar></Navbar>
    <div v-if="isLoaded" class="py-4">
        <h1 class="text-3xl text-center font-bold mb-6">Book your coach</h1>
        <div class="flex justify-center px-2">
            <div class="card-container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                <Card v-for="item in coaches" :content="item" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Card from '../common/Card.vue';
import Navbar from './Navbar.vue';
import { base_url } from '../../../config/config';
export default {
    data() {
        return {
            coaches: [],
            isLoaded: false,
        }
    },
    components: { Navbar, Card },
    methods: {
        fetchCoaches() {
            let token = localStorage.getItem("access_token")
            axios.get(`${base_url}/api/coach`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                this.coaches = response.data.data;

                this.coaches = this.coaches?.map(coach => {
                    return {
                        ...coach,
                        profile_pic: `${base_url}${coach.profile_pic}`
                    };
                });
            }).catch(error => {
                console.error('Error in fetching coaches:', error);
            });
        },
    },
    created() {
        this.fetchCoaches(),
        this.isLoaded = true
    }
}

</script>
