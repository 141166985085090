<!-- <template>
  <div>
    <navbar></navbar>
    <h1>Login</h1>
    <form @submit.prevent="login">
      <input type="text" v-model="username" placeholder="Username" required />
      <input
        type="password"
        v-model="password"
        placeholder="Password"
        required
      />
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Navbar from "./Navbar.vue";
import { base_url } from "../../config/config";
export default {
  components: { Navbar },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    login() {
      axios
        .post(`${base_url}/api/auth/login/`, {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          localStorage.setItem("access_token", response.data.access);
          localStorage.setItem("refresh_token", response.data.refresh);
          this.$toast.success("Login Successful", {
            position: 'bottom',
          });
          // Redirect to the dashboard or another page
          this.$router.push("/booking");
        })
        .catch((error) => {
          this.$toast.error("Login Failed! Check Credentials", {
            position: 'bottom',
          });
        });
    },
  },
};
</script>

<style scoped>
.login {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  margin-top: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  display: inline-block;
  padding: 10px 15px;
  background: #009900;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background: #009900;
}
</style> -->


<template>
  <div>
    <navbar></navbar>
    <div class="flex flex-col items-center w-full p-6">
      <form @submit.prevent="login" class="login-form w-full max-w-md bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h1 class="text-3xl text-center font-bold mb-6">Login</h1>
        <div class="form-group mb-4">
          <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email</label>
          <input type="text" v-model="email" id="email" placeholder="Email" required
            class="input-field w-full px-3 py-2 border rounded" />
        </div>
        <div class="form-group mb-4">
          <label for="password" class="block text-gray-700 text-sm font-bold mb-2">Password</label>
          <input type="password" v-model="password" id="password" placeholder="Password" required
            class="input-field w-full px-3 py-2 border rounded" />
        </div>
        <div class="form-group flex items-center justify-between">
          <button type="submit"
            class="submit-button w-full bg-dark-green-theme hover:bg-green-700 text-white font-bold py-2 px-4 rounded my-4">Login</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Navbar from "./Navbar.vue";
import { base_url } from "../../../config/config";
export default {
  components: { Navbar },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      axios.post(`${base_url}/api/login`, {
        email: this.email,
        password: this.password,
      }).then((response) => {
        localStorage.setItem("access_token", response.data.data.access_token);
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        this.$toast.success("Login Successful", {
          position: 'top',
        });

        if (response.data.data.user.role == "admin") {
          this.$router.push("/admin/")
        }
        else {
          this.$router.push("/coaches");
        }
      }).catch((error) => {
        this.$toast.error("Login Failed! Check Credentials", {
          position: 'top',
        });
      });
    },
  },
};
</script>

<style scoped>
.login-form {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-field {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.form-group {
  margin-bottom: 16px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

button[disabled],
input[disabled] {
  cursor: not-allowed;
  background: #cccccc;
}
</style>
