<template>
    <div v-if="content" class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <img class="rounded-t-lg w-full h-72"
            :src="content.profile_pic"
            alt="" />
        <div class="pt-5 px-5">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-dark-green-theme">{{ content.first_name }} {{
                content.last_name }}</h5>
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-balance">{{ content.description }}</p>
        </div>
        <div class="flex justify-center">
            <Ratings :ratings="content?.average_rating"/>
        </div>
        <div class="text-right font-bold px-4 pt-4 text-dark-green-theme">
            ${{ content.per_hour_price }}
        </div>
        <div class="px-5">
            <RouterLink :to="`/coaches/details/${content.id}`">
                <Button btnText="Book" />
            </RouterLink>
        </div>
    </div>
</template>

<script>
import { RouterLink } from 'vue-router';
import Button from './Button.vue';
import Ratings from './Ratings.vue';

export default {
    components: { Button, Ratings },
    props: ['content']
}
</script>