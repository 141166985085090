<template>
    <AdminLayout v-slot>
        <div class="bg-white rounded-xl shadow-lg p-10">
            <div class="relative overflow-x-auto">
                <div class="flex justify-between">
                    <div>
                        <h1 class="text-2xl font-bold my-2 text-dark-green-theme">Coaches</h1>
                    </div>
                    <div>
                        <RouterLink to="/admin/coach/details">
                            <button type="button"
                                class="text-white bg-dark-green-theme hover:bg-light-green-theme font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Add
                                New</button>
                        </RouterLink>
                    </div>
                </div>
                <hr class="my-3" />
                <table class="w-full text-base text-left rtl:text-right text-gray-500">
                    <thead class="text-sm text-dark-green-theme uppercase bg-green-50">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                First Name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Last Name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Email
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Phone No.
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in coaches" class="bg-white border-b dark:border-gray-700">
                            <th scope="row"
                                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {{ item.first_name }}
                            </th>
                            <td class="px-6 py-4">
                                {{ item.last_name }}
                            </td>
                            <td class="px-6 py-4">
                                {{ item.email }}
                            </td>
                            <td class="px-6 py-4">
                                {{ item.phone_number }}
                            </td>
                            <td class="flex px-6 py-4">
                                <div>
                                    <RouterLink :to="`/admin/coach/details/${item.id}`">
                                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none"
                                            viewBox="0 0 24 24">
                                            <path stroke="green" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2"
                                                d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z" />
                                        </svg>
                                    </RouterLink>
                                </div>

                                <div @click="deleteCoach(item.id)">
                                    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                        viewBox="0 0 24 24">
                                        <path stroke="green" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z" />
                                    </svg>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </AdminLayout>
</template>

<script>
import AdminLayout from '@/layout/AdminLayout.vue';
import axios from 'axios';
import { base_url } from '../../../config/config';
import { RouterLink } from 'vue-router';
export default {
    components: { AdminLayout },
    data() {
        return {
            coaches: []
        };
    },
    methods: {
        fetchCoaches() {
            let token = localStorage.getItem("access_token")
            axios.get(`${base_url}/api/coach`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                this.coaches = response.data.data;
            }).catch(error => {
                console.error('Error in fetching coaches:', error);
            });
        },
        deleteCoach(id) {
            let token = localStorage.getItem("access_token")
            axios.delete(`${base_url}/api/coach/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                this.$toast.success(response.data.message, {
                    position: 'top-right',
                });
                this.coaches = this.coaches.filter(item => item.id != id)

            }).catch(error => {
                console.error('Error in fetching users:', error);
            });
        }
    },
    created() {
        this.fetchCoaches()
    }
}
</script>