<template>
    <AdminLayout v-slot>
        <div v-if="isLoaded">
            <form @submit.prevent="register" class="register-form bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <h1 class="text-2xl font-bold my-2 text-dark-green-theme">Register User</h1>
                <hr class="my-3 mb-6" />
                <div class="grid grid-rows-3 grid-col-2 grid-flow-col gap-4">
                    <div class="form-group mb-4">
                        <label for="firstname" class="block text-gray-700 text-sm font-bold mb-2">First Name</label>
                        <input type="text" v-model="formData.first_name" id="firstname" placeholder="First Name"
                            required class="input-field w-full px-3 py-2 border rounded" />
                    </div>
                    <div class="form-group mb-4">
                        <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email</label>
                        <input type="email" v-model="formData.email" id="email" placeholder="Email" required
                            class="input-field w-full px-3 py-2 border rounded" />
                    </div>
                    <div class="form-group mb-4">
                        <label for="password" class="block text-gray-700 text-sm font-bold mb-2">Password</label>
                        <input type="password" v-model="formData.password" id="password" placeholder="Password" required
                            class="input-field w-full px-3 py-2 border rounded" />
                    </div>
                    <div class="form-group mb-4">
                        <label for="lastname" class="block text-gray-700 text-sm font-bold mb-2">Last Name</label>
                        <input type="text" v-model="formData.last_name" id="lastname" placeholder="Last Name" required
                            class="input-field w-full px-3 py-2 border rounded" />
                    </div>
                    <div class="form-group mb-4">
                        <label for="phone_number" class="block text-gray-700 text-sm font-bold mb-2">Phone No.</label>
                        <input type="text" v-model="formData.phone_number" id="phone_number" placeholder="Phone No."
                            required class="input-field w-full px-3 py-2 border rounded" />
                    </div>
                    <div class="form-group mb-4">
                        <label for="cpassword" class="block text-gray-700 text-sm font-bold mb-2">Confirm
                            Password</label>
                        <input type="password" v-model="formData.password_confirmation" id="password_confirmation"
                            placeholder="Confirm Password" required
                            class="input-field w-full px-3 py-2 border rounded" />
                    </div>
                </div>
                <!-- <div class="form-group mb-4">
              <label for="role" class="block text-gray-700 text-sm font-bold mb-2">Role</label>
              <select v-model="selectedRole" id="role" required class="input-field w-full px-3 py-2 border rounded">
                <option disabled value="">Select a role</option>
                <option value="student">Student</option>
                <option value="coach">Coach</option>
              </select>
            </div> -->
                <div class="form-group flex items-center justify-between">
                    <button type="submit"
                        class="submit-button w-full bg-dark-green-theme hover:bg-green-700 text-white font-bold py-2 px-4 rounded my-6">Register</button>
                </div>
            </form>
        </div>
    </AdminLayout>
</template>

<script>
import axios from 'axios';
import Navbar from './Navbar.vue';
import AdminLayout from '@/layout/AdminLayout.vue';
import { base_url } from '../../../config/config';

export default {
    components: { Navbar, AdminLayout },
    data() {
        return {
            formData: {
                first_name: '',
                last_name: '',
                role: 'user',
                password: '',
                email: '',
                phone_number: '',
                password_confirmation: '',
                id: null
            },
            saving: false,
            isLoaded: false
        };
    },
    methods: {
        createUser(token, data) {
            axios.post(`${base_url}/api/user/create`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                this.$toast.success(response.data.message, {
                    position: 'top',
                });
                this.$router.push("/admin/users")
            }).catch(error => {
                console.error('Error registering:', error);
            });
        },
        updateUser(token, data) {
            axios.post(`${base_url}/api/update/user/${this.id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                this.$toast.success(response.data.message, {
                    position: 'top',
                });
                this.$router.push("/admin/users")
            }).catch(error => {
                console.error('Error registering:', error);
            });
        },
        async fetchUser(id) {
            let token = localStorage.getItem("access_token")
            await axios.get(`${base_url}/api/users/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                console.log(response);
                this.formData = response.data.data[0]
            });
        },
        register() {
            let token = localStorage.getItem("access_token")
            this.saving = true
            
            if (this.formData.password != this.formData.password_confirmation) {
                this.$toast.error("Password not matching", {
                    position: 'top',
                });
                return
            }

            let user = new FormData();
            user.append('first_name', this.formData.first_name);
            user.append('last_name', this.formData.last_name);
            user.append('email', this.formData.email);
            user.append('password', this.formData.password);
            user.append('phone_number', this.formData.phone_number);
            user.append('role', this.formData.role);
            user.append('password_confirmation', this.formData.password_confirmation);

            if (this.id) {
                this.updateUser(token, user)
            }
            else {
                this.createUser(token, user)
            }

            this.saving = false
        },
        // loginAfterRegister() {
        //     axios.post('/api/auth/login/', {
        //         username: this.username,
        //         password: this.password,
        //     })
        //         .then(response => {
        //             localStorage.setItem('access_token', response.data.access);
        //             localStorage.setItem('refresh_token', response.data.refresh);
        //             this.$router.push('/dashboard');
        //         })
        //         .catch(error => {
        //             console.error('Error logging in:', error);
        //         });
        // },
    },
    async mounted() {
        this.id = this.$route.params.id ?? null
        if (this.id) {
            await this.fetchUser(this.id).then(() => {
                this.isLoaded = true;
            })
        }
        this.isLoaded = true;
    }
};
</script>

<style scoped>
.register-form {
    background: #ffffff;
    padding: 2% 4%;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

.input-field {
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group {
    margin-bottom: 16px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
}

button[disabled],
input[disabled] {
    cursor: not-allowed;
    background: #cccccc;
}
</style>