import { createRouter, createWebHistory } from "vue-router";
import Login from "../pages/User/Login.vue";
import Review from "../components/Review.vue";
import Booking from "../pages/User/Booking.vue";
import ScheduleManagement from "../components/ScheduleManagement.vue";
import UserManagement from "../components/UserManagement.vue";
import LessonManagement from "../components/LessonManagement.vue";
import Home from "../pages/User/Home.vue";
import Register from "../pages/User/Register.vue";
import Coaches from "@/pages/User/Coaches.vue";
import CoachDetails from "@/pages/User/CoachDetails.vue";
import Dashboard from "@/pages/Admin/Dashboard.vue";
import Users from "@/pages/Admin/Users.vue";
import UserDetails from "@/pages/Admin/UserDetails.vue";
import AdminPanelCoaches from "@/pages/Admin/Coaches.vue";
import AdminPanelCoachDetails from "@/pages/Admin/CoachDetails.vue";
import Success from "@/pages/User/Success.vue";
import Cancel from "@/pages/User/Cancel.vue";
import Bookings from "@/components/Admin/Bookings.vue";


const routes = [
  { path: "/login", component: Login },
  { path: "/", component: Home },
  { path: "/register", component: Register },
  {
    path: "/review", component: Review,
    meta: { requiresAuth: true, roles: ["user"] }
  },
  {
    path: "/coaches", component: Coaches,
    meta: { requiresAuth: true, roles: ["user"] }
  },
  {
    path: "/coaches/booking/:coach_id", component: Booking,
    meta: { requiresAuth: true, roles: ["user"] }
  },
  {
    path: "/success", component: Success,
    meta: { requiresAuth: true, roles: ["user"] }
  },
  {
    path: "/cancel", component: Cancel,
    meta: { requiresAuth: true, roles: ["user"] }
  },
  {
    path: "/coaches/details/:id", component: CoachDetails,
    meta: { requiresAuth: true, roles: ["user"] }
  },
  // { path: "/schedule-management", component: ScheduleManagement },
  // { path: "/user-management", component: UserManagement },
  // { path: "/lesson-management", component: LessonManagement },
  {
    path: "/admin/", component: Dashboard,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/admin/bookings", component: Bookings,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/admin/users", component: Users,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/admin/user/details", component: UserDetails,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/admin/user/details/:id", component: UserDetails,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/admin/coaches", component: AdminPanelCoaches,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/admin/coach/details", component: AdminPanelCoachDetails,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/admin/coach/details/:id", component: AdminPanelCoachDetails,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
];

// const routes = [
//   {
//     path: '/',
//     component: CustomerLayout,
//     children: [
//       { path: '', component: Home },
//       { path: 'about', component: About },
//       { path: 'contact', component: Contact },
//     ],
//   },
//   {
//     path: '/admin',
//     component: AdminLayout,
//     children: [
//       { path: '', component: AdminHome },
//       { path: 'settings', component: AdminSettings },
//       { path: 'users', component: AdminUsers },
//     ],
//   },
// ];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  let user = JSON.parse(localStorage.getItem("user"))
  const userRole = user?.role;
  if (to.meta.requiresAuth && to.meta.roles) {
    if (to.meta.roles.includes(userRole)) {
      next();
    } else {
      next("/login"); // User is not allowed, redirect to login
    }
  } else {
    next(); // No role restriction, proceed to the route
  }
});


export default router;
