<template>
    <AdminLayout v-slot>
        <div v-if="isLoaded" class="bg-white rounded-xl shadow-lg p-10">
            <div class="relative overflow-x-auto">
                <div class="flex justify-between">
                    <div>
                        <h1 class="text-2xl font-bold my-2 text-dark-green-theme">Bookings</h1>
                    </div>
                </div>
                <hr class="my-3" />
                <table class="w-full text-base text-left rtl:text-right text-gray-500">
                    <thead class="text-sm text-dark-green-theme uppercase bg-green-50">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Date
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Time
                            </th>
                            <th scope="col" class="px-6 py-3">
                                User Name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Coach Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in bookings" class="bg-white border-b dark:border-gray-700">
                            <th scope="row"
                                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {{ item.date }}
                            </th>
                            <td class="px-6 py-4">
                                {{ item.time }}
                            </td>
                            <th scope="row"
                                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {{ item.user_name }}
                            </th>
                            <td class="px-6 py-4">
                                {{ item.coach_name }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </AdminLayout>
</template>

<script>
import AdminLayout from '@/layout/AdminLayout.vue';
import axios from 'axios';
import { base_url } from '../../../config/config';
import { RouterLink } from 'vue-router';
export default {
    components: { AdminLayout },
    data() {
        return {
            bookings: [],
            isLoaded: false
        };
    },
    methods: {
        fetchBookings() {
            let token = localStorage.getItem("access_token")
            axios.get(`${base_url}/api/booking/listing`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                this.bookings = response.data.data;
            }).catch(error => {
                console.error('Error in fetching coaches:', error);
            });
        },
    },
    created() {
        this.fetchBookings()
        this.isLoaded = true
    }
}
</script>