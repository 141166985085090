<template>
    <div class="flex flex-col items-center justify-center h-screen">
      <h1
        class="mb-4 text-5xl font-bold leading-none tracking-tight text-red-600 dark:text-white">
        Payment Cancelled</h1>
      <p class="mb-6 text-lg font-normal text-red-500 sm:px-16 xl:px-48">Your Payment has been cancelled</p>
      <button
        class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-red-600 rounded-lg"
        @click="goToDashboard">
        Back To Dashboard
        <svg class="w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 14 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M1 5h12m0 0L9 1m4 4L9 9" />
        </svg>
      </button>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      goToDashboard() {
        this.$router.push('/coaches');
      },
    },
  };
  </script>