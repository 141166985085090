<template>
    <button type="submit"
        class="submit-button w-full bg-dark-green-theme hover:bg-green-700 text-white font-bold py-2 px-4 rounded my-4">{{ btnText }}</button>
</template>

<script>
export default {
    props: [
        "btnText"
    ]
}
</script>