<template>
    <Navbar />
    <div v-if="isLoaded">
        <div class="bg-white rounded-lg shadow pt-6 pb-2">
            <!-- <div class="flex w-4/5 mx-auto">
                <div class="flex justify-center items-center w-1/4">
                    <img class="w-40 h-40 rounded-full mr-4" :src="coach.profile_pic" alt="Profile Picture" />
                </div>
                <div class="w-3/4">
                    <div class="mb-2">
                        <h2 class="text-2xl font-bold text-dark-green-theme ">{{ coach.first_name }} {{
                            coach.last_name }}</h2>
                        <span class="text-dark-green-theme ml-1 flex">
                            <p class="font-semibold">Price </p>
                            <p class="font-extrabold mx-2"> ${{ coach.per_hour_price }}</p>
                        </span>
                        <div>
                            <Ratings :ratings="coach.average_rating" />
                        </div>
                    </div>
                    <p class="text-justify">{{ coach.description }}</p>
                </div>
            </div> -->
            <div class="flex w-4/5 mx-auto">
                <div class="flex justify-center items-center w-1/4">
                    <img class="w-40 h-40 rounded-full mr-4" :src="coach.profile_pic" alt="Profile Picture" />
                </div>
                <div class="w-3/4">
                    <div class="flex justify-between items-center">
                        <div class="mb-2">
                            <h2 class="text-2xl font-bold text-dark-green-theme ">{{ coach.first_name }} {{
                                coach.last_name }}</h2>
                            <p class="text-light-green-theme font-semibold">Price ${{ coach.per_hour_price }}</p>
                        </div>
                        <div>
                            <Ratings :ratings="coach.average_rating" />
                        </div>
                    </div>
                    <p class="text-justify">{{ coach.description }}</p>
                </div>
            </div>
            <div class="flex w-4/5 mx-auto justify-end">
                <div class="w-1/6">
                    <RouterLink :to="`/coaches/booking/${coachID}`">
                        <Button btnText="Book" />
                    </RouterLink>
                </div>
            </div>
        </div>
        <div class="bg-white rounded-lg shadow p-6 my-4">
            <div class="w-1/3 mx-auto">
                <div class="flex justify-between my-4">
                    <label class="text-gray-700 text-base font-bold">Rate your coach:</label>
                    <div class="flex">
                        <svg v-for="star in 5" :key="star"
                            :class="{ 'text-yellow-300': star <= rating, 'text-gray-400': star > rating }"
                            @click="rate(star)" class="w-6 h-6 cursor-pointer fill-current"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polygon points="12 2 15 8.5 22 9.3 17 14 18 21 12 17.3 6 21 7 14 2 9.3 9 8.5 12 2" />
                        </svg>
                    </div>
                </div>
                <div class="my-2">
                    <textarea class="input-field w-full px-3 py-2 border rounded" placeholder="Write your review"
                        v-model="comment" />
                </div>
                <div class="form-group flex items-center justify-between">
                    <button type="button" @click="createReviews"
                        class="submit-button w-full bg-dark-green-theme hover:bg-green-700 text-white font-bold py-2 px-4 rounded my-4"
                        :disabled="!enableReviewButton">Submit
                    </button>
                </div>
            </div>
        </div>
        <div class="bg-white rounded-lg shadow p-6">
            <h3 class="text-xl font-semibold mb-4">Reviews</h3>
            <div class="overflow-x-auto">
                <table class="min-w-full bg-white">
                    <thead>
                        <tr>
                            <th
                                class="px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                Reviewer
                            </th>
                            <th
                                class="px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                Review
                            </th>
                            <th
                                class="px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                Ratings
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="review in reviews" :key="review.id">
                            <td class="px-4 py-2 border-b border-gray-200">{{ review?.user_name }}</td>
                            <td class="px-4 py-2 border-b border-gray-200">{{ review?.comment }}</td>
                            <td class="px-4 py-2 border-b border-gray-200">
                                <Ratings :ratings="review?.rating_number" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { RouterLink } from 'vue-router';
import Button from '../common/Button.vue';
import Ratings from '../common/Ratings.vue';
import Navbar from './Navbar.vue';
import axios from 'axios';
import { base_url } from '../../../config/config';
export default {
    name: "ProfilePage",
    components: { Navbar, Ratings, Button },
    data() {
        return {
            rating: 0,
            coachID: null,
            isLoaded: false,
            coach: {
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                image: '',
                description: '',
                average_rating: 0,
                per_hour_price: '',
                profile_pic: '',
            },
            comment: '',
            reviews: [],
            token: '',
            enableReviewButton: true
        };
    },
    methods: {
        rate(star) {
            this.rating = star;
        },
        async fetchCoach(id, token) {
            await axios.get(`${base_url}/api/coach/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                this.coach.first_name = response.data.data[0].first_name
                this.coach.last_name = response.data.data[0].last_name
                this.coach.email = response.data.data[0].email
                this.coach.phone_number = response.data.data[0].phone_number
                this.coach.image = response.data.data[0].image
                this.coach.description = response.data.data[0].description
                this.coach.average_rating = response.data.data[0].average_rating
                this.coach.per_hour_price = response.data.data[0].per_hour_price
                this.coach.profile_pic = base_url + response.data.data[0].profile_pic
            }).catch(error => {
                console.error('Error in fetching coaches:', error);
            });
        },
        async createReviews() {

            let userID = JSON.parse(localStorage.getItem("user"))?.id
            this.enableReviewButton = false;
            let data = {
                user_id: userID,
                coach_id: this.coachID,
                rating_number: this.rating,
                comment: this.comment
            }

            await axios.post(`${base_url}/api/coach/review/create`, data, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            }).then((response) => {
                this.$toast.success(response.data.message, {
                    position: 'top',
                })
                this.enableReviewButton = true
                this.rating = 0
                this.comment = ''
            })
        },
        async fetchReviews(id, token) {
            await axios.get(`${base_url}/api/coach/review/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((res) =>
                this.reviews = res.data.data.reviews
            )
        }
    },
    async mounted() {
        this.coachID = this.$route.params.id;
        this.token = localStorage.getItem("access_token")
        let self = this
        if (this.coachID) {
            await this.fetchCoach(this.coachID, this.token).then(() => {
                self.fetchReviews(self.coachID, self.token).then(() =>
                    this.isLoaded = true
                )
            })
        }
        this.isLoaded = true;
    }
}
</script>


<style>
.input-field {
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
}

.star {
    cursor: pointer;
}
</style>