<template>
    <AdminLayout v-slot>
        <div v-if="isLoaded" class="bg-white rounded-xl shadow-lg p-10">
            <div>
                <h1 class="text-2xl font-bold my-2 text-dark-green-theme">Stats</h1>
            </div>
            <hr class="my-4" />
            <div class="flex justify-around my-10">
                <div>
                    <p
                        class="flex flex-col items-center max-w-sm p-6 bg-white border border-dark-green-theme rounded-lg shadow px-auto hover:bg-gray-100">
                        <!-- <svg class="w-6 h-6 text-center text-gray-800 dark:text-white" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="green" viewBox="0 0 24 24">
                            <path fill-rule="evenodd"
                                d="M5 5a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1h1a1 1 0 0 0 1-1 1 1 0 1 1 2 0 1 1 0 0 0 1 1 2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a2 2 0 0 1 2-2ZM3 19v-7a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Zm6.01-6a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-10 4a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm2 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
                                clip-rule="evenodd" />
                        </svg> -->
                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-dark-green-theme dark:text-white">This Month
                        bookings
                    </h5>
                    <p class="font-bold text-2xl text-center text-dark-green-theme dark:text-gray-400">{{ total_booking }}</p>
                    </p>
                </div>
                <div>
                    <p
                        class="flex flex-col items-center max-w-sm p-6 bg-white border border-dark-green-theme rounded-lg shadow text-center hover:bg-gray-100">
                        <!-- <svg class="w-[40px] h-[40px] text-gray-800 dark:text-white" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="green" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M8 17.345a4.76 4.76 0 0 0 2.558 1.618c2.274.589 4.512-.446 4.999-2.31.487-1.866-1.273-3.9-3.546-4.49-2.273-.59-4.034-2.623-3.547-4.488.486-1.865 2.724-2.899 4.998-2.31.982.236 1.87.793 2.538 1.592m-3.879 12.171V21m0-18v2.2" />
                        </svg> -->

                    <h5 class="mb-2 text-2xl font-bold tracking-tight text-dark-green-theme dark:text-white">This Month
                        payments
                    </h5>
                    <p class="font-bold text-2xl text-center text-dark-green-theme dark:text-gray-400">$ {{ total_payment}}</p>
                    </p>
                </div>
            </div>
        </div>
    </AdminLayout>
</template>

<script>
import AdminLayout from '@/layout/AdminLayout.vue';
import axios from 'axios';
import { base_url } from '../../../config/config';
export default {
    components: { AdminLayout },
    data() {
        return {
            total_payment: 0,
            total_booking: 0,
            isLoaded: false
        }
    },
    methods: {
        fetchBookings(token) {
            axios.get(`${base_url}/api/total-booking`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    this.total_booking = response.data.data.totalBooking
                })
                .catch(error => {
                    console.error('Error registering:', error);
                });
        },

        fetchPayments(token) {
            axios.get(`${base_url}/api/total-payment`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    this.total_payment = response.data.data.totalPayments
                })
                .catch(error => {
                    console.error('Error registering:', error);
                });
        }
    },
    mounted() {
        let token = localStorage.getItem("access_token");
        this.fetchBookings(token);
        this.fetchPayments(token);
        this.isLoaded = true;
    }
}
</script>