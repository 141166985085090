<template>
    <AdminLayout v-slot>
        <div>
            <form v-if="isLoaded" @submit.prevent="register"
                class="register-form bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <h1 class="text-2xl font-bold my-2 text-dark-green-theme">Register Coaches</h1>
                <hr class="my-3 mb-6" />
                <div class="grid grid-rows-3 grid-cols-2 grid-flow-col gap-4">
                    <div class="form-group mb-4">
                        <label for="firstname" class="block text-gray-700 text-sm font-bold mb-2">First Name</label>
                        <input type="text" v-model="first_name" id="firstname" placeholder="First Name" required
                            class="input-field w-full px-3 py-2 border rounded" />
                    </div>
                    <div class="form-group mb-4">
                        <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email</label>
                        <input type="email" v-model="email" id="email" placeholder="Email" required
                            class="input-field w-full px-3 py-2 border rounded" />
                    </div>
                    <div class="form-group mb-4">
                        <label for="image" class="block text-gray-700 text-sm font-bold mb-2">Image</label>
                        <input type="file" id="image" @change="handleFileUpload"
                            class="input-field w-full px-3 py-0 border rounded" />
                    </div>
                    <div class="form-group mb-4">
                        <label for="lastname" class="block text-gray-700 text-sm font-bold mb-2">Last Name</label>
                        <input type="text" v-model="last_name" id="lastname" placeholder="Last Name" required
                            class="input-field w-full px-3 py-2 border rounded" />
                    </div>
                    <div class="form-group mb-4">
                        <label for="phone_number" class="block text-gray-700 text-sm font-bold mb-2">Phone No.</label>
                        <input type="number" v-model="phone_number" id="phone_number" placeholder="Phone No." required
                            class="input-field w-full px-3 py-2 border rounded" />
                    </div>
                    <div class="form-group mb-4">
                        <label for="per_hour_price" class="block text-gray-700 text-sm font-bold mb-2">Price per
                            hour</label>
                        <input type="number" v-model="per_hour_price" id="per_hour_price" placeholder="Price per hour"
                            required class="input-field w-full px-3 py-2 border rounded" />
                    </div>
                </div>
                <div>
                    <div class="form-group mb-4">
                        <label for="text" class="block text-gray-700 text-sm font-bold mb-2">Description</label>
                        <textarea rows="4" type="description" v-model="description" id="description" placeholder="Description"
                            required class="input-field w-full px-3 py-2 border rounded" />
                    </div>
                </div>
                <!-- <div>
                    <label for="text" class="block text-gray-700 text-sm font-bold mb-2">Image Preview</label>
                    <img :src="profile_pic_preview" height="auto" width="100px"/>
                </div> -->
                <div class="form-group flex items-center justify-between">
                    <button type="submit"
                        class="submit-button w-full bg-dark-green-theme hover:bg-green-700 text-white font-bold py-2 px-4 rounded my-6" :disabled="saving">Register</button>
                </div>
            </form>
            <Loader v-else/>
        </div>
    </AdminLayout>
</template>

<script>
import axios from 'axios';
import Navbar from './Navbar.vue';
import AdminLayout from '@/layout/AdminLayout.vue';
import { base_url } from '../../../config/config';
import Loader from '../common/Loader.vue';

export default {
    components: { Navbar, AdminLayout },
    data() {
        return {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            image: '',
            per_hour_price: '',
            description: '',
            profile_pic: null,
            profile_pic_preview: null,
            isLoaded: false,
            saving: false,
            id: null
        };
    },
    methods: {
        handleFileUpload(event) {
            const file = event.target.files[0];
            this.profile_pic = file;
        },
        async fetchCoach(id) {
            let token = localStorage.getItem("access_token")
            await axios.get(`${base_url}/api/coach/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                this.first_name = response.data.data[0].first_name
                this.last_name = response.data.data[0].last_name
                this.email = response.data.data[0].email
                this.phone_number = response.data.data[0].phone_number
                this.image = response.data.data[0].image
                this.description = response.data.data[0].description
                this.per_hour_price = response.data.data[0].per_hour_price
                this.profile_pic = response.data.data[0].profile_pic
                this.profile_pic_preview = base_url+response.data.data[0].profile_pic
            }).catch(error => {
                console.error('Error in fetching coaches:', error);
            });
        },
        createCoach(token, formData) {
            axios.post(`${base_url}/api/coach`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    this.$toast.success(response.data.message, {
                        position: 'top',
                    });
                    this.$router.push("/admin/coaches")
                })
                .catch(error => {
                    console.error('Error registering:', error);
                });
        },
        updateCoach(token, formData) {
            axios.put(`${base_url}/api/update/coach/${this.id}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    this.$toast.success(response.data.message, {
                        position: 'top',
                    });
                    this.$router.push("/admin/coaches")
                })
                .catch(error => {
                    console.error('Error registering:', error);
                });
        },
        register() {
            this.saving = true;
            let token = localStorage.getItem("access_token")
            let formData = new FormData();
            formData.append('first_name', this.first_name);
            formData.append('last_name', this.last_name);
            formData.append('email', this.email);
            formData.append('per_hour_price', this.per_hour_price);
            formData.append('phone_number', this.phone_number);
            formData.append('role', 'coach');
            formData.append('description', this.description);
            formData.append('profile_pic', this.profile_pic);

            if (this.id) {
                this.updateCoach(token, formData)
            }
            else {
                this.createCoach(token, formData)
            }

            this.saving = false;
        },
    },
    async mounted() {
        this.id = this.$route?.params?.id ?? null
        if (this.id) {
            await this.fetchCoach(this.id).then(() => {
                this.isLoaded = true;
            })
        }
        this.isLoaded = true;
    }
};
</script>

<style scoped>
.register-form {
    background: #ffffff;
    padding: 2% 4%;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

.input-field {
    width: 100%;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group {
    margin-bottom: 16px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
}

button[disabled],
input[disabled] {
    cursor: not-allowed;
    background: #cccccc;
}
</style>