<!-- <template>
  <div>
    <navbar></navbar>
    <div class="booking-container">
      <h1>Book a Tennis Lesson</h1>
      <form @submit.prevent="submitBooking">
        <div class="form-group">
          <label for="coach">Select Coach:</label>
          <select v-model="booking.coach" id="coach" required>
            <option v-for="coach in coaches" :key="coach.id" :value="coach.id">
              {{ coach.name }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="date">Select Date:</label>
          <input type="date" v-model="booking.date" id="date" required />
        </div>

        <div class="form-group">
          <label for="time">Select Time:</label>
          <input type="time" v-model="booking.time" id="time" required />
        </div>

        <button type="submit" class="btn btn-primary">Book Lesson</button>
      </form>

      <div v-if="message" class="message">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Navbar from "./Navbar.vue";

export default {
  components: { Navbar },
  data() {
    return {
      booking: {
        coach: "",
        date: "",
        time: "",
      },
      coaches: [],
      message: "",
    };
  },
  created() {
    this.fetchCoaches();
  },
  methods: {
    fetchCoaches() {
      axios
        .get("/api/coaches")
        .then((response) => {
          this.coaches = response.data;
        })
        .catch((error) => {
          console.error("There was an error fetching the coaches:", error);
        });
    },
    submitBooking() {
      axios
        .post("/api/bookings", this.booking)
        .then((response) => {
          this.message = "Booking successful!";
          this.booking = { coach: "", date: "", time: "" }; // Reset form
        })
        .catch((error) => {
          console.error("There was an error submitting the booking:", error);
          this.message = "Failed to book lesson. Please try again.";
        });
    },
  },
};
</script>

<style scoped>
.booking-container {
  max-width: 500px;
  margin: 0 auto;
  margin-top:20px !important;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  background-color: #0056b3;
}

.message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: #dff0d8;
  color: #3c763d;
}
</style> -->


<template>
  <navbar></navbar>
  <div v-if="isLoaded">
    <div class="bg-white rounded-lg shadow pt-6 pb-6">
      <div class="flex w-4/5 mx-auto">
        <div class="flex justify-center items-center w-1/4">
          <img class="w-40 h-40 rounded-full mr-4" :src="coach.profile_pic" alt="Profile Picture" />
        </div>
        <div class="w-3/4">
          <div class="flex justify-between items-center">
            <div class="mb-2">
              <h2 class="text-2xl font-bold text-dark-green-theme ">{{ coach.first_name }} {{ coach.last_name }}</h2>
              <p class="text-light-green-theme font-semibold">Price ${{ coach.per_hour_price }}</p>
            </div>
            <div>
              <Ratings :ratings="coach.average_rating" />
            </div>
          </div>
          <p class="text-justify">{{ coach.description }}</p>
        </div>
      </div>
    </div>

    <div class="py-6">
      <form @submit.prevent="submitBooking" class="booking-form bg-white shadow-md rounded mb-4">

        <div class="flex flex-col justify-center items-center">
          <h1 class="text-2xl font-bold mb-6 text-dark-green-theme">Book your coaching lessons</h1>
          <div class="form-group w-1/3">
            <label for="date" class="block text-gray-700 text-sm font-bold mb-2">Select Date:</label>
            <input type="date" v-model="booking.date" id="date" required
              class="input-field w-full px-3 py-2 border rounded" />
          </div>

          <div class="form-group w-1/3">
            <label for="time" class="block text-gray-700 text-sm font-bold mb-2">Select Time:</label>
            <input type="time" v-model="booking.time" id="time" required
              class="input-field w-full px-3 py-2 border rounded" />
          </div>

        </div>

        <div class="form-group flex items-center justify-center">
          <button type="submit"
            class="submit-button w-1/3 bg-dark-green-theme hover:bg-green-700 text-white font-bold py-2 px-4 rounded my-4">Book
            Lesson
          </button>
        </div>
        <div v-if="message" class="message mt-4 p-4 border rounded bg-red-100 text-center text-red-700">{{ message }}

        </div>
        <div v-if="success_message" class="message mt-4 p-4 border rounded bg-green-100 text-center text-green-700">{{
          message }}

        </div>

      </form>

    </div>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import axios from "axios";
import Navbar from "./Navbar.vue";
import Ratings from "../common/Ratings.vue";
import { loadStripe } from "@stripe/stripe-js";
import { base_url } from "../../../config/config";

export default {
  components: { Navbar, Ratings, StripeCheckout },
  data() {
    return {
      publishableKey: '',
      stripe: '',
      coach: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        image: '',
        per_hour_price: '',
        description: '',
        average_rating: 0,
        profile_pic: null,
      },
      booking: {
        coach_id: "",
        user_id: "",
        date: "",
        time: "",
        status: false
      },
      isLoaded: false,
      message: "",
    };
  },
  methods: {
    submitBooking() {
      var token = localStorage.getItem("access_token")
      this.message = "";
      axios.post(`${base_url}/api/booking`, this.booking, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then((response) => {
          this.success_message = "Booking successful!";
          this.checkout(this.coach.per_hour_price)
        })
        .catch((error) => {
          console.error("There was an error submitting the booking:", error);
          this.message = error.response.data.message;
        });
    },
    updateBooking() {
      var token = localStorage.getItem("access_token")
      this.message = "";
      axios.post(`${base_url}/api/booking/update/${this.booking.id}`, this.booking, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then((response) => {
          this.message = "Payment successful!";
        })
        .catch((error) => {
          console.error("There was an error in payment:", error);
          this.message = error.response.data.message;
        });
    },
    setID() {
      let userDetails = JSON.parse(localStorage.getItem("user"))
      this.booking.coach_id = this.$route.params.coach_id
      this.booking.user_id = userDetails.id
    },
    // async handleSubmit() {
    //   try {
    //     const response = await fetch('<http://localhost:3000/create-checkout-session>', {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify({ donation: this.form.donation }),
    //     });
    //     const session = await response.json();
    //     const { error } = await this.stripe.redirectToCheckout({
    //       sessionId: session.id,
    //     });
    //     if (error) {
    //       this.message = error.message;
    //     }
    //   } catch (error) {
    //     this.message = error.message;
    //   }
    // },
    async fetchCoach(id) {
      let token = localStorage.getItem("access_token")
      await axios.get(`${base_url}/api/coach/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        this.coach.first_name = response.data.data[0].first_name
        this.coach.last_name = response.data.data[0].last_name
        this.coach.email = response.data.data[0].email
        this.coach.phone_number = response.data.data[0].phone_number
        this.coach.image = response.data.data[0].image
        this.coach.description = response.data.data[0].description
        this.coach.average_rating = response.data.data[0].average_rating
        this.coach.per_hour_price = response.data.data[0].per_hour_price
        this.coach.profile_pic = base_url + response.data.data[0].profile_pic
      }).catch(error => {
        console.error('Error in fetching coaches:', error);
      });
    },
    async checkout(price) {
      let token = localStorage.getItem("access_token")
      const data = {
        currency: "usd",
        source: "tok_visa",
        amount: price,
        success_url: "http://localhost:8080/success"
      }

      const stripeSession = await axios.post(`${base_url}/api/checkout`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      const { error } = await this.stripe.redirectToCheckout({
        sessionId: stripeSession.data.data.sessionId,
      });

      if (error) {
        this.message = error.message;
        return
      }
    }
  },
  computed: {
    $style() {
      return styles;
    },
  },
  async mounted() {
    this.setID();
    this.stripe = await loadStripe('pk_test_0rY5rGJ7GN1xEhCB40mAcWjg');
    console.log(this.stripe);
    if (this.booking.coach_id) {
      await this.fetchCoach(this.booking.coach_id).then(() => {
        this.isLoaded = true;
      })
    }
    this.isLoaded = true;
  },
};
</script>

<style scoped>
.booking-form {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-field {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.form-group {
  margin-bottom: 16px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

.message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
}

button[disabled],
input[disabled] {
  cursor: not-allowed;
  background: #cccccc;
}
</style>
